import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AppointmentsManagementApi {
  private readonly _appointmentsChildren = new BehaviorSubject<string[] | null>(null);

  appointmentsChildren: Observable<string[] | null> = this._appointmentsChildren.asObservable();

  setAppointmentsChildren(modalitiesMap: string[]): void {
    this._appointmentsChildren.next(modalitiesMap);
  }
}
