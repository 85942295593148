import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NapaEvent } from '@core/models/entities/event.models';
import { EventsApi } from '@core/services/api/events.api';
import { CardComponent } from '@shared/components/card/card.component';
import { CircleCloseIconComponent } from '@shared/components/icons/circle-close-icon/circle-close-icon.component';
import { MenuIconComponent } from '@shared/components/icons/menu-icon/menu-icon.component';
import { MenuDirective } from '@shared/directives/menu.directive';
import { SCHEDULER_EVENT } from '@shared/modules/scheduler/constants/scheduler.constants';
import { GridAdapter } from '@shared/modules/scheduler/models/grid-adaper/grid-adaper.models';
import { SchedulerEvent } from '@shared/modules/scheduler/models/scheduler/scheduler.models';
import { SessionSchedulerEvent } from '../../../models/session-scheduler.models';
import { SessionSchedulerApi } from '../../../services/session-scheduler.api';
import { DialogApi } from '@shared/modules/dialog/services/dialog.api';
import { EditKidDialogComponent } from '../../edit-kid-dialog/edit-kid-dialog.component';
import { EditKidDialogConfig } from '../../../models/assign-kid.models';
import { SessionEvent } from '@core/models/entities/session.models';
import { TimeSlotParams } from '@shared/modules/scheduler/models/time-slot/time-slot.models';
import {
  SCHEDULER_SESSION_EVENT,
  SCHEDULER_SESSION_ID,
  SCHEDULER_TIME_SLOT_PARAMS,
} from '../../../constants/session-scheduler.constants';
import { addMinutes } from '../../../utils/session-scheduler.utils';
import { EditIconComponent } from '@shared/components/icons/edit-icon/edit-icon.component';
import { ModalitiesComponent } from '@shared/components/modalities/modalities.component';
import { ModalitiesManagementApi } from '../../../services/modalities.api';
import { AppointmentsManagementApi } from '../../../services/appointments.api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { RxLet } from '@rx-angular/template/let';

@UntilDestroy()
@Component({
  selector: 'napa-session-event',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    MenuDirective,
    MenuIconComponent,
    MatMenuModule,
    CircleCloseIconComponent,
    EditIconComponent,
    ModalitiesComponent,
    RxLet,
  ],
  templateUrl: './session-event.component.html',
  styleUrl: './session-event.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionEventComponent implements OnInit {
  protected event!: NapaEvent;
  private highlightedEventSubject = new BehaviorSubject<number | null>(null);
  highlightedEvent$ = this.highlightedEventSubject.asObservable();

  constructor(
    private injector: Injector,
    private eventsApi: EventsApi,
    private gridAdapter: GridAdapter,
    private sessionSchedulerApi: SessionSchedulerApi,
    private modalitiesManagementApi: ModalitiesManagementApi,
    private appointmentsManagementApi: AppointmentsManagementApi,
    private dialogApi: DialogApi,
    @Inject(SCHEDULER_EVENT) public schedulerEvent: SchedulerEvent<SessionSchedulerEvent>,
    @Inject(SCHEDULER_SESSION_ID) private sessionId: string,
    @Inject(SCHEDULER_SESSION_EVENT) private sessionEvent: SessionEvent,
    @Inject(SCHEDULER_TIME_SLOT_PARAMS) private timeSlotParams: TimeSlotParams
  ) {
    this.event = this.schedulerEvent.data!.event!;
  }

  ngOnInit(): void {
    this.appointmentsManagementApi.appointmentsChildren.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res) return;
      const index = res?.indexOf(this.event.child!._id);
      if (index > -1) {
        this.highlightedEventSubject.next(index);
      } else {
        this.highlightedEventSubject.next(null);
      }
    });
  }

  getRowBorderColor(index: number | null): string {
    if (index === null) return '';

    return `color color-event-${(index % 40) + 1}`;
  }

  remove(): void {
    this.eventsApi.delete(this.event._id).subscribe(() => {
      this.gridAdapter.removeOne(this.event._id);

      this.modalitiesManagementApi.removeItemsFromMap(this.event.child!._id, this.event.modality);

      this.sessionSchedulerApi.notifyChildRemoval();
    });
  }

  editKid(): void {
    this.dialogApi.openCustomPopup(EditKidDialogComponent, this.editKidDialogConfig, {
      maxWidth: '384px',
      autoFocus: false,
      injector: this.editKidDialogInjector,
    });
  }

  private get editKidDialogConfig(): EditKidDialogConfig {
    return {
      data: {
        sessionId: this.sessionId,
        eventId: this.event._id,
        event: this.sessionEvent,
        start: this.timeSlotParams.startRowId,
        clientEvent: this.schedulerEvent.data,
        end: addMinutes(this.timeSlotParams.endRowId, 30),
      },
    };
  }

  private get editKidDialogInjector(): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: GridAdapter, useValue: this.gridAdapter }],
    });
  }
}
