<ng-container *rxLet="highlightedEvent$ | async as highlightedEventIndex">
  <napa-card [ngClass]="getRowBorderColor(highlightedEventIndex)">
    <div class="session-event-header">
      <div class="session-event-header__section">{{ event.child!.full_name }}</div>

      <napa-menu-icon [matMenuTriggerFor]="menu"></napa-menu-icon>
    </div>

    <napa-modalities [modalities]="event.modality" [maxLength]="3"></napa-modalities>
  </napa-card>
</ng-container>

<mat-menu #menu="matMenu" napaMenu>
  <ng-template matMenuContent let-session>
    <button mat-menu-item (click)="remove()">
      <napa-circle-close-icon></napa-circle-close-icon>

      <div class="napa-medium-text">Remove Client</div>
    </button>
    <button mat-menu-item (click)="editKid()">
      <napa-edit-icon></napa-edit-icon>

      <div class="napa-medium-text">Edit Schedule</div>
    </button>
  </ng-template>
</mat-menu>